import React from 'react'

import styles from './Books.module.css'
import chooseLife from '../../assets/img/choose-life.jpg'

export const Books = () => (
    <div className={styles.books}>

        <div>
            <div className={styles.book}>
                <a href="https://www.amazon.com/dp/B076DCS2Z2" target="_blank"><img src={chooseLife} alt="Choose Life" /></a>
                <span><a href="https://www.amazon.com/dp/B076DCS2Z2" target="_blank">Buy now on Amazon</a></span>
            </div>

            <h1>Choose Life</h1>
            <p>Thank you for showing interest in the book I wrote to help people step off the hamster wheel of ‘life’.
            Below is an excerpt from the book's introduction which will give you an insight into my mindset
            and inspirations for writing the book. I am consistently amazed and humbled by the feedback and remain
            at your disposal to answer any further questions. You can always reach out to me via <a href="https://twitter.com/PrinceySOV" target="_blank">Twitter</a>.</p>

            <p>Thank you.</p>
        </div>

        <div className={styles.bookExcerpt}>
            <h2>Introduction</h2>

            <blockquote className={styles.quote}>
                “It's almost impossible for a piece of writing to change someone. It's definitely impossible for it to change everyone. So... who is this designed to reach?” - Seth Godin
            </blockquote>

            <p>I know what you’re thinking: Is this book for me?</p>

            <p>This powerful question from Seth is one I took the time to fully digest. In fact, go back and read it again for yourself right now. </p>

            <p>I considered that question long and hard, before, during and after I wrote this book. I pictured you in my mind’s eye and wondered about who you are, what you believe and why you are here with my book in your hands, contemplating whether or not it’s worth your time to read what I have worked so hard to put into words.</p>

            <p>You’re at a point in your life where something simply feels ‘off’ with your life balance. You may even be contemplating the possibility of escaping your 9-to-5 routine (more like 8-to-8) and taking back the reigns to your own life. </p>

            <p>But you don’t know where to start. </p>

            <p>You’ve got responsibilities, including a spouse and kids to care for. You’re wrestling with what seem to be two opposing forces: the duty to bring home the bacon and your desire for greater freedom from a soul-sucking job. You want to provide for your family, but you’ve realised that your current path to achieve that goal has made you nothing more than a weekend lodger in your own home. </p>

            <p>And so you’ve begun searching for a different path. Maybe you’ve talked to friends about your frustrations at work, found a blog article or two about family travel, or even joined a forum online to discuss establishing a source of location independent income. But you’re still not sure how to put all the pieces of the puzzle together. And now you’re here, with my book in your hand, and you’re wondering, “Is this book for me?”</p>

            <p>Well, let’s get to the crux of it right now.</p>

            <p>This book is for you, the life-balance-seeker, the 9-to-5 escapist, and the family provider who actually wants to spend time with your family instead of working your life away for somebody else.</p>

            <p>You just need that social proof, the validation of knowing that somebody else has already taken that huge leap of faith into the unknown before you and kindly trodden a slightly beaten path for you to explore.</p>

            <p>Now, take a deep breath and get ready for what you never thought you would find: a book that does just that. </p>

            <p>In fact, by picking up this book you are further down the path to your freedom than you ever thought possible. This likely isn’t the first stage of your search for a proper escape route. You have already done much of the work over countless conversations about saying goodbye to the 9-to-5 life and taking off to travel the world with your family. Your subconscious has already run wild with the endless hurdles, outcomes and possibilities to make it all happen, probably keeping you awake at night and daydreaming in the office! </p>

            <p>You’ve realised what 95% of the population has yet to discover: there could be a different path, another choice. That’s a phenomenal first step, but if you’re anything like I was, you’re probably thinking, “Okay, now what? How do I go from wanting something different to actually achieving it?”  </p>

            <p>When my wife and I made the big realization that we needed to change our lives, we didn’t have much else to go on to guide us toward that lifestyle. In an effort to make the process a little easier for those who are following in our footsteps, I have written the book that I wish my wife and I had at our disposal when we were making the biggest decision of our lives.</p>

            <p>This book is the final piece of your puzzle. </p>

            <p>Come the end of it, you won’t be daydreaming anymore. There will be no more time for procrastination. You will clear your head by killing the inner demons and will silence your doubters by knowing the answers to all their “what ifs” and “how-to’s” for every step of the journey. Your plans will be put into massive action mode, propelling you and your family headfirst into the life you want to lead!</p>

            <p>If that sounds like exactly what you need, then this book is for you. </p>

            <blockquote className={styles.quote}>
                “This is your last chance. After this, there is no turning back. You take the blue pill - the story ends, you wake up in your bed and believe whatever you want to believe. You take the red pill - you stay in Wonderland and I show you how deep the rabbit-hole goes.”
                - Morpheus
</blockquote>
        </div>

    </div>
)