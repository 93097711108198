import { useEffect, useState } from "react"
import axios from 'axios'

export const useEpisodes = (reset = 0) => {
    const [episodes, setEpisodes] = useState()
    useEffect(() => {
        const getEpisodes = async () => {
            const res = await axios.get('episodes')
            setEpisodes(res.data)
        }
        getEpisodes()
    }, [reset])
    return episodes
}

