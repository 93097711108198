import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'

import styles from './Episode.module.css'
import { formatContent, formatPubDate } from '../textFormatters'

export const Episode = ({ data }) => {
    const [expanded, setExpanded] = useState(true)
    const [showDesc, setShowDesc] = useState(false)

    useEffect(() => {
        setShowDesc(false)
        setExpanded(true)
    }, [data])

    const { content, link, pubDate, title } = data

    const descFormatted = formatContent(content)
    const shortDescFormatted = `${descFormatted.substr(0, 300)}...`
    const embed = link.replace('/episodes', '/embed/episodes')

    const ShortDesc = () => (
        <Fragment>
            <div dangerouslySetInnerHTML={{ __html: shortDescFormatted }} /> <button onClick={() => setShowDesc(true)}>Show more</button>
        </Fragment>
    )

    const LongDesc = () => (
        <Fragment>
            <div dangerouslySetInnerHTML={{ __html: descFormatted }} />
            <button onClick={() => setShowDesc(false)}>Show less</button>
        </Fragment>
    )

    return (
        <div className={classnames(styles.episode, expanded ? '' : styles.small)}>
            <button className={classnames(styles.viewEpBtn, expanded ? styles.hideBtn : '')} onClick={() => setExpanded(!expanded)}>{expanded ? 'Hide episode' : 'Show episode'}</button>
            <div className={expanded ? '' : styles.hidden}>
                <iframe src={embed} height="100px" width="100%" frameBorder="0" scrolling="no"></iframe>
                <h2>{title}</h2>
                <p>Published: {formatPubDate(pubDate)}</p>
                {showDesc ? <LongDesc /> : <ShortDesc />}
            </div>
        </div>
    )
}