// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2WYxHRnBCdmkfYfDmp5JDU {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._3zaHGWVVWlN7lF_DQdxCiS {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n._2ZzALWqUMjwJ9ceRkDI-ws {\n    float: left;\n    min-width: 170px;\n    min-height: 170px;\n    max-width: 170px;\n    max-height: 170px;\n    margin: 0 20px 10px 0;\n    border-radius: 50%;\n    overflow: hidden;\n}\n\n.KFKrpkGWv31gMq5sjF-Fp {\n    clear: both;\n}", "",{"version":3,"sources":["webpack://src/components/pages/About.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".about {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.thanks {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.princey {\n    float: left;\n    min-width: 170px;\n    min-height: 170px;\n    max-width: 170px;\n    max-height: 170px;\n    margin: 0 20px 10px 0;\n    border-radius: 50%;\n    overflow: hidden;\n}\n\n.whois {\n    clear: both;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": "_2WYxHRnBCdmkfYfDmp5JDU",
	"thanks": "_3zaHGWVVWlN7lF_DQdxCiS",
	"princey": "_2ZzALWqUMjwJ9ceRkDI-ws",
	"whois": "KFKrpkGWv31gMq5sjF-Fp"
};
export default ___CSS_LOADER_EXPORT___;
