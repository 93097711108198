import React from 'react'
import { formatPubDate } from '../textFormatters'

import styles from './Episode.module.css'

const formatPrice = text => {
    if (!text) return
    if (text.indexOf('ATH') > -1) {
        return text.replace('  ', ' ')
    } else if (text.indexOf('BTC') > -1) {
        return text.replace('$ ', '$')
            .replace('$- ', '$')
            .replace('BTC$', 'BTC $')
            .replace('BTC/$', 'BTC $')
            .replace('BTC-$', 'BTC $')
            .replace('.', '')
            .replace("'", ',')
    } else {
        return
    }
}

export const EpisodeSmall = ({ data }) => {
    const btcPrice = formatPrice(data.contentSnippet && data.contentSnippet.split('\n')[0])
    return (
        <h2 className={styles.smallTitle}>
            <div className={styles.datePrice}>
                <div className={styles.date}>{formatPubDate(data.pubDate)}</div>
                <div className={styles.price}>{btcPrice ? `${btcPrice}` : ''}</div>
            </div>
            <div>{data.title}</div>
        </h2>
    )
}