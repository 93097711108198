// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1yGy1BzNUeHBv9xGnN_Pi- {\n    text-align: center;\n}\n\n._1ih0DRu6UbrlU2njRdapTe {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 12px;\n    align-items: center;\n    justify-content: center;\n}\n\n._1ih0DRu6UbrlU2njRdapTe img {\n    width: 32px;\n}", "",{"version":3,"sources":["webpack://src/components/Links.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".title {\n    text-align: center;\n}\n\n.links {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 12px;\n    align-items: center;\n    justify-content: center;\n}\n\n.links img {\n    width: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_1yGy1BzNUeHBv9xGnN_Pi-",
	"links": "_1ih0DRu6UbrlU2njRdapTe"
};
export default ___CSS_LOADER_EXPORT___;
