// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._18TNqpvIORngFmEwIccjBC {\n    margin-top: 0;\n}\n\n._17b14nQGEkUr_4XmgZkcBA {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._18TNqpvIORngFmEwIccjBC hr {\n    margin: 20px 0;\n}\n\nh1.jiNLCEWIOF29LdFXH4Rra {\n    text-align: center;\n    font-size: 2.2em;\n    margin-bottom: 40px;\n    line-height: 1.2em;\n}\n\n._2nzkC0_aPGsmX0IGv76iFX {\n    text-align: center;\n    font-size: 36px;\n    margin: 40px 0;\n}\n\n@media screen and (min-width: 1024px) {\n    ._18TNqpvIORngFmEwIccjBC {\n        margin-top: 40px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/pages/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".home {\n    margin-top: 0;\n}\n\n.content {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.home hr {\n    margin: 20px 0;\n}\n\nh1.title {\n    text-align: center;\n    font-size: 2.2em;\n    margin-bottom: 40px;\n    line-height: 1.2em;\n}\n\n.humble {\n    text-align: center;\n    font-size: 36px;\n    margin: 40px 0;\n}\n\n@media screen and (min-width: 1024px) {\n    .home {\n        margin-top: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "_18TNqpvIORngFmEwIccjBC",
	"content": "_17b14nQGEkUr_4XmgZkcBA",
	"title": "jiNLCEWIOF29LdFXH4Rra",
	"humble": "_2nzkC0_aPGsmX0IGv76iFX"
};
export default ___CSS_LOADER_EXPORT___;
