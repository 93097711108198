import React from 'react'

import coinfloor from '../../assets/img/sponsors/coinfloor.png'
import swan from '../../assets/img/sponsors/swan-bitcoin.png'
import escapeArtist from '../../assets/img/sponsors/escapeartist.png'
import shamory from '../../assets/img/sponsors/SHAmory.png'
import relai from '../../assets/img/sponsors/relai.png'
import stackinsat from '../../assets/img/sponsors/stackinsat.png'
import galileo from '../../assets/img/sponsors/galileo.png'
import bitbox from '../../assets/img/sponsors/bitbox02.png'
import bitcoinReserve from '../../assets/img/sponsors/bitcoinreserve.svg'
import satsback from '../../assets/img/sponsors/satsback.svg'
import bitcoinday from '../../assets/img/sponsors/bitcoinday.png'

import styles from './Sponsors.module.css'
import { Sponsor } from '../layouts/Sponsor'

export const Sponsors = () => (
    <div className={styles.sponsors}>
        <h1>Sponsors</h1>

        <div className={styles.sponsorsGrid}>
            <Sponsor title="Coinfloor UK" url="https://coinfloor.co.uk/bitten" icon={coinfloor}>
                <p>After interviewing <a href="https://twitter.com/obi" target="_blank">Obi Nwosu</a>, CEO of <a href="http://coinfloor.co.uk/bitten" target="_blank">Coinfloor</a>, a UK based Bitcoin exchange, it became obvious to us both that we were very aligned on our thinking and ethos around Bitcoin and life. I am truly grateful to Obi and Coinfloor for placing their trust in me and becoming the first ever sponsor of the Once Bitten podcast.</p>
                <p>You can use <a href="http://coinfloor.co.uk/bitten" target="_blank">our affiliate link</a> to start your stacking journey 😉</p>
            </Sponsor>

            <Sponsor title="Swan Bitcoin" url="https://www.swanbitcoin.com/oncebitten" icon={swan}>
                <p><a href="https://www.swanbitcoin.com/oncebitten" target="_blank">Swan Bitcoin</a> have fast become one of the most respected Bitcoin only companies in the business. I have had the pleasure to meet and interview many of the individual Bitcoin Maxis that make up the team at Swan. I remain humbled that the founders <a href="https://twitter.com/coryklippsten" target="_blank">Cory Klippsten</a> and <a href="https://twitter.com/skwp" target="_blank">Yan Pritzker</a> place their trust in me and sponsor the show from across the pond!</p>
                <p>You can visit Swan to start stacking and <span className={styles.discountCode}>claim $10 USD in Bitcoin</span> by using <a href="https://www.swanbitcoin.com/oncebitten" target="_blank">our affiliate link</a>.</p>
            </Sponsor>

            <Sponsor title="Escape Artist" url="https://www.escapeartist.store/?ref=990187" icon={escapeArtist}>
                <p>A previous guest on the show Mikkel Thorrup from Escape Artist (Ep# 65) has been kind enough to offer listeners to this show a discounted rate on all of his material.</p>
                <p>Simply follow <a href="https://www.escapeartist.store/?ref=990187" target="_blank">this link</a> to check out Mikkel’s work and figure out how you can practice Flag Theory, invest in schemes to get you a second passport/citizenship in a tax free domicile, live like a king, all completely legal!</p>
            </Sponsor>

            <Sponsor title="SHAmory" url="https://shamory.com/product/shamory/" icon={shamory}>
                <p>It's an honour to represent <a href="https://twitter.com/PlaySHAmory" target="_blank">@PlaySHAmory</a> on this site to help founder and previous guest <a href="https://twitter.com/ScottMSibley" target="_blank">@ScottMSibley</a> promote his awesome family card game. This is a STEM certified game which is a massive coup for the first Bitcoin game ever made.</p>
                <p>You can use the code <span className={styles.discountCode}>oncebitten10</span> for a <span className={styles.discountCode}>10% discount</span> at <a href="https://shamory.com/product/shamory/" target="_blank">https://shamory.com/product/shamory/</a></p>
                <p>To help support the cause you can buy some gifts for friends and family for their first touch point of Bitcoin!</p>
            </Sponsor>

            <Sponsor title="Relai" url="https://relai.ch/" icon={relai}>
                <p>I have had the pleasure of interviewing Julian Liniger (<a href="http://once-bitten.com/#/episodes/2bfb72e6-9867-444d-b119-e83d71e5e3ca">Ep 79</a>), and am proud to be trusted to support the brand and personally use their service.</p>
                <p>Download the <a href="https://relai.ch/" target="_blank">Relai app</a> from Apple Store or Google Play Store to start stacking Sats in Europe.</p>
                <p>Use the code <span className={styles.discountCode}>REL727</span> to save on commission fees!</p>
            </Sponsor>

            <Sponsor title="StackinSat" url="https://www.stackinsat.com/" icon={stackinsat}>
                <p>Vous habitez en France? Vous voulez empiler des sats et économiser dans Bitcoin?</p>
                <p><a href="https://www.stackinsat.com/" target="_blank">Stackinsat.com</a> vous a couvert!</p>
                <p>Un service uniquement Bitcoin pour vous aider à acheter chaque semaine.</p>
                <p>Utilisez le code PROMO - <span className={styles.discountCode}>Bitten</span> - pour économiser sur la commission!</p>
            </Sponsor>

            <Sponsor title="Galileo" url="https://galileoxp.com/" icon={galileo}>
                <p>It's my pleasure to introduce you to <a href="https://galileoxp.com/" target="_blank">Galileo</a>, the Self Directed Learning online school for kids. My own children, including show co-host Lauren, are part of this school.</p>
                <p>You can find my interview with the CEO and Cofounder, <a href="https://twitter.com/Vladstan" target="_blank">@Vladstan</a>, on <a href="http://once-bitten.com/#/episodes/0321e730-f86f-4cdf-a0eb-412712b50e33">episode #116</a> of the Once Bitten Podcast.</p>
                <p>Use Code <span className={styles.discountCode}>Danielprince</span> to unlock some extra features and benefits when you register your interest to learn more.</p>
            </Sponsor>

            <Sponsor title="Shift Crypto" url="https://shiftcrypto.ch/bitten" icon={bitbox}>
                <p>I am honoured to be sponsored by <a href="https://shiftcrypto.ch/bitten" target="_blank">Shift Crypto</a> - a Swiss company building a great Hardware wallet.</p>
                <p>The Bitbox02 is a Bitcoin-only wallet that will help you take control of your bitcoin and help you become more self-sovereign!</p>
            </Sponsor>

            <Sponsor title="Bitcoin Reserve" url="https://bitcoinreserve.com/ref/bitten" icon={bitcoinReserve}>
                <p>It's a pleasure to introduce <a href="https://bitcoinreserve.com/ref/bitten" target="_blank">Bitcoin Reserve</a>, the first bitcoin-only venue in Europe that allows daily purchases of over &euro;1,000.</p>
                <p>Created for serious beginners and investors, the platform provides the most user-friendly bitcoin buying experience in Europe.</p>
                <p>Their 'Concierge' is a service for high-net-worth individuals and institutions in Europe and abroad, looking to buy over &euro;50,000 in bitcoin.</p>
                <p>Offering brokerage features, fast onboarding, a high-security/high-privacy approach, multi-region compatibility, and highly personalised 24/7 personal service.</p>
            </Sponsor>

            <Sponsor title="Satsback" url="https://satsback.com/register/5AxjyPRZV8PNJGlM" icon={satsback}>
                <p>Ever wanted to get bitcoin back for purchases?</p>
                <p><a href="https://satsback.com/register/5AxjyPRZV8PNJGlM" target="_blank">Satsback</a> is an evolution of cashback and loyalty rewards. We work with online stores to help you save in bitcoin whenever you shop online.</p>
                <p>Experience the future of saving and value transfer today!</p>
                <p>Use the referral link below to get started, then share your own link with friends and family!</p>
                <p><a href="https://satsback.com/register/5AxjyPRZV8PNJGlM" target="_blank">Satsback.com</a></p>
            </Sponsor>

            <Sponsor title="BitcoinDay" url="https://bitcoinday.io/" icon={bitcoinday}>
                <p>If you are in the US and would like to join small bitcoin 1-day events check out <a href="https://Bitcoinday.io" target="_blank">https://Bitcoinday.io</a> and <span className={styles.discountCode}>save 10%</span> with the code <span className={styles.discountCode}>OB10</span>.</p>
            </Sponsor>
        </div>

    </div >
)