// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qDGL7oKh_DTPITZq5rxzD {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._3jRV66pCLTPgk4cf0XvQJy {\n    margin: 0 auto;\n    position: relative;\n    width: 100%;\n    height: 0;\n    padding-top: 56.25%;\n}\n\n._3yZcU3nUYvUaUqcmmjrqiR {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n\n@media screen and (min-width: 768px) {\n    ._3jRV66pCLTPgk4cf0XvQJy {\n        width: 600px;\n        padding-top: 40%;\n    }\n}\n\n@media screen and (min-width: 992px) {\n    ._3jRV66pCLTPgk4cf0XvQJy {\n        width: 800px;\n        padding-top: 38%;\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    ._3jRV66pCLTPgk4cf0XvQJy {\n        width: 800px;\n        padding-top: 30%;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/pages/YoutubePlaylist.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".textWrapper {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.wrapper {\n    margin: 0 auto;\n    position: relative;\n    width: 100%;\n    height: 0;\n    padding-top: 56.25%;\n}\n\n.video {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n\n@media screen and (min-width: 768px) {\n    .wrapper {\n        width: 600px;\n        padding-top: 40%;\n    }\n}\n\n@media screen and (min-width: 992px) {\n    .wrapper {\n        width: 800px;\n        padding-top: 38%;\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    .wrapper {\n        width: 800px;\n        padding-top: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textWrapper": "qDGL7oKh_DTPITZq5rxzD",
	"wrapper": "_3jRV66pCLTPgk4cf0XvQJy",
	"video": "_3yZcU3nUYvUaUqcmmjrqiR"
};
export default ___CSS_LOADER_EXPORT___;
