import React from 'react'

import styles from './YoutubePlaylist.module.css'

export const YoutubePlaylist = () => (
    <div>
        <div className={styles.textWrapper}>
            <p>Embedded below is the YouTube playlist of podcasts that have an associated video.</p>
            <p>It really helps <a href="https://www.youtube.com/channel/UC0Ufr7kEzbHMAzIcB4eM1xQ" target="_blank">the channel</a> grow if you subscribe and like videos. Thanks!</p>
        </div>
        <div className={styles.wrapper}>
            <iframe className={styles.video} src="https://www.youtube.com/embed/videoseries?list=PLWIEN8RK7PKGlNjo_Am19vTNxUuSj41Kv" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="true"></iframe>
        </div>
    </div>
)