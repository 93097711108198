import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import styles from './Header.module.css'
import logo from '../assets/img/Once-BITten-Cover-Art-sm.jpg'

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const NavLinks = () => (
    <ul className={styles.navLinks}>
        <li><NavLink exact activeClassName={styles.active} to="/">Home</NavLink></li>
        <li><NavLink exact activeClassName={styles.active} to="/episodes">Podcast</NavLink></li>
        <li><NavLink exact activeClassName={styles.active} to="/video">Video</NavLink></li>
        <li><NavLink exact activeClassName={styles.active} to="/about">About</NavLink></li>
        <li><NavLink exact activeClassName={styles.active} to="/sponsors">Sponsors</NavLink></li>
        <li><NavLink exact activeClassName={styles.active} to="/books">Books</NavLink></li>
    </ul>

)

export const Header = () => {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [navOpen, setNavOpen] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            if (window.innerWidth > 1023 && navOpen) setNavOpen(false)
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    const Hamburger = () => (
        <div className={styles.hamburger} onClick={() => setNavOpen(!navOpen)}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )

    const onGoHome = () => history.push('/')

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logoContainer}>
                    <img src={logo} className={styles.logo} onClick={onGoHome} />
                    <div className={styles.titleContainer} onClick={onGoHome}>
                        <h1 className={styles.title}>Once BITten</h1>
                        <span className={styles.strapline}>A Bitcoin Podcast</span>
                    </div>
                </div>
                <div className={styles.middle}>
                    <div className={styles.navMain}>
                        <NavLinks />
                    </div>
                    <Hamburger />
                </div>
                <div className={styles.right} />
            </div>
            {navOpen ?
                <div className={styles.navFloat}>
                    <NavLinks />
                </div>
                : null
            }
        </div>
    )
}