// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./buttons.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js!./forms.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js!./links.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js!./lists.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n}\n\nbody, html {\n  width: 100%;\n  height: 100%;\n}\n\nbody {\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  font-size: 14px;\n  line-height: 1.6em;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://src/assets/styles/global.css"],"names":[],"mappings":"AAKA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,8DAA8D;EAC9D,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb","sourcesContent":["@import './buttons.css';\n@import './fonts.css';\n@import './forms.css';\n@import './links.css';\n@import './lists.css';\n* {\n  box-sizing: border-box;\n}\n\nbody, html {\n  width: 100%;\n  height: 100%;\n}\n\nbody {\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  font-size: 14px;\n  line-height: 1.6em;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
