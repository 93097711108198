import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import styles from './App.module.css'
import { Home } from './pages/Home'
import { About } from './pages/About'
import { Episodes } from './pages/Episodes'
import { Footer } from './Footer'
import { Episode } from './Episode'
import { Header } from './Header'
import { Sponsors } from './pages/Sponsors'
import { Books } from './pages/Books'
import { YoutubePlaylist } from './pages/YoutubePlaylist'

const App = () => {
    const [selectedEp, setSelectedEp] = useState()
    return (
        <div className={styles.withBg}>
            {selectedEp ? <Episode data={selectedEp} /> : null}
            <div className={styles.wrapper}>
                <Header />
                <div>
                    <div className={styles.content}>
                        <Switch>
                            <Route path="/episodes/:guid">
                                <Episodes setSelectedEp={ep => setSelectedEp(ep)} />
                            </Route>
                            <Route path="/episodes">
                                <Episodes setSelectedEp={ep => setSelectedEp(ep)} />
                            </Route>
                            <Route path="/video">
                                <YoutubePlaylist />
                            </Route>
                            <Route path="/about">
                                <About />
                            </Route>
                            <Route path="/sponsors">
                                <Sponsors />
                            </Route>
                            <Route path="/books">
                                <Books />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

App.displayName = 'App'

export default App