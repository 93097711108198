import React from 'react'
import { Link } from 'react-router-dom'

import styles from './Home.module.css'

export const Home = () => (
    <div className={styles.home}>
        <div className={styles.content}>

            <h1 className={styles.title}>Welcome To The Bitcoin Rabbit Hole</h1>

            <p>Bitcoin can be a very deep and confusing rabbit hole to fall into, this podcast is for people who are looking for answers.</p>

            <p>Daniel Prince, author of <Link to="/books">Choose Life</Link>, is on a mission to share real life stories of how Bitcoin has changed and shaped individuals and families lives forever.</p>

            <p>Are you considering investing in Bitcoin but too skeptical?</p>

            <p>Are you afraid that you have missed the boat and the price is too high or are you unsure of what Bitcoin even is?</p>

            <p>Are you looking for a way to change your families life, invest for the future, store your wealth or hedge against the current financial system?</p>

            <p>Could Bitcoin be for you?</p>

            <hr />

            <p>This podcast is produced by <a href="https://twitter.com/adamwoodhams1" target="_blank">@adamwoodhams1</a> to who I remain truly grateful and am lucky to have been chosen by him to take on this Bitcoin project!</p>

            <p>This website has been designed and developed by <a href="https://twitter.com/JimReaperMusic" target="_blank">@JimReaperMusic</a> to whom I remain amazed by his skills and willingness to help give back to the Bitcoin space.</p>

            <p>Both of these guys work on this project in their spare time juggling life, 'real' jobs and stacking sats!</p>

            <p>Bitcoin is the most amazing space to be exposed to!</p>

            <p className={styles.humble}>Stay Humble.</p>
        </div>
    </div>
)