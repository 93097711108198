import React from 'react'

import styles from './Sponsor.module.css'

export const Sponsor = ({ icon, url, title, children }) => (
    <div className={styles.sponsor}>
        <div className={styles.logo}>
            <img src={icon} alt=" " />
        </div>
        <div className={styles.sponsorInfo}>
            <h2 className={styles.title}><a href={url} target="_blank">{title}</a></h2>
            {children}
        </div>
    </div>
)