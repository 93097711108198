// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2azJaIvK_0X_8XD-OI0D0G {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._2stK7u0RxS_1TzM7bjFZdk {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 12px;\n}\n\n._1HVhCMJukyizvM6E6jFn1L {\n    position: relative;\n    margin-left: 4px;\n    top: -2px;\n    font-size: 32px;\n    cursor: pointer;\n}\n\n._2h3lvELsiw5tVlRTEeUVlC {\n    outline-color: transparent;\n}", "",{"version":3,"sources":["webpack://src/components/pages/Episodes.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".pageTop {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.filter {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 12px;\n}\n\n.clearFilter {\n    position: relative;\n    margin-left: 4px;\n    top: -2px;\n    font-size: 32px;\n    cursor: pointer;\n}\n\n.epTitle {\n    outline-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTop": "_2azJaIvK_0X_8XD-OI0D0G",
	"filter": "_2stK7u0RxS_1TzM7bjFZdk",
	"clearFilter": "_1HVhCMJukyizvM6E6jFn1L",
	"epTitle": "_2h3lvELsiw5tVlRTEeUVlC"
};
export default ___CSS_LOADER_EXPORT___;
