import React from 'react'

import styles from './Footer.module.css'
import twitter from '../assets/img/links/twitter.png'
import linkedin from '../assets/img/links/linkedin.png'
import email from '../assets/img/links/email.png'

import { Links } from './Links'

const contactLinks = [
    ['Twitter', twitter, 'https://twitter.com/PrinceySOV'],
    ['LinkedIn', linkedin, 'https://www.linkedin.com/in/daniel-prince-70357b2a/'],
    ['email', email, 'mailto:76princey@gmail.com'],
]

const LinkItem = ({ data }) => (<a href={data[2]} target="_blank" title={data[0]}><img src={data[1]} alt={data[0]} /></a>)

export const Footer = () => {
    const currYear = new Date().getFullYear()
    const startYear = currYear === 2020 ? '' : '2020 - '
    const links = []
    for (const item of contactLinks) {
        links.push(<LinkItem key={item[0]} data={item} />)
    }
    return (
        <div className={styles.footer}>
            <div className={styles.siteBy}>Site by <a href="https://twitter.com/JimReaperMusic" target="_blank">Jim Reaper</a></div>
            <div className={styles.copyright}>
                <span>&copy; {startYear}{currYear} <a href="https://twitter.com/PrinceySOV" target="_blank">Daniel Prince</a></span>
                <span className={styles.contactLinks}>{links}</span>
            </div>
            <div className={styles.links}>
                <Links />
            </div>
        </div>
    )
}