import Autolinker from 'autolinker'

export const formatContent = content => {
    if (!content) return ''

    let text = content
    const lowered = content.toLowerCase()

    if (lowered.indexOf('@hodler than thou') > -1)
        text = content.replace('@Hodler than thou', '@HodlerThanThou')

    return Autolinker.link(text
        .replace(/medium.com\/@/g, 'medium.com/') // remove @ from medium links to avoid twitterfying in next step
        .replace(/(@\w+)/g, '<a href="https://twitter.com/$1" target="_blank">$1</a>')
        .replace(/medium.com\//g, 'medium.com/@') // replace @ in medium links
    )
}

export const formatPubDate = date => (date.substr(0, date.length - 13))