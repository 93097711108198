import 'normalize.css'
import './assets/styles/global.css'

import React from 'react'
import { render } from 'react-dom'
import App from './components/App'
import { HashRouter as Router } from 'react-router-dom'

const appEl = document.createElement('div')
document.body.append(appEl)

render(
  <Router>
    <App />
  </Router>,
  appEl
)
