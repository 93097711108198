// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qskn_HwRIf9OTSlEVvFvp {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    background-color: rgba(100, 100, 100, 0.2);\n    padding: 12px;\n    border-radius: 6px;\n}\n\n._3kNWARuAkoD226IOvSXMF- {\n    height: 70px;\n    max-width: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n._3kNWARuAkoD226IOvSXMF->img {\n    max-width: 200px;\n    max-height: 70px;\n}\n\n.XeC8AVIkcKivUVMVwbGXr {\n    display: flex;\n    flex-direction: column;\n}\n\n._27Vf5znypLKj8wxMEP1F4G {\n    margin: 16px 0 0;\n}\n\n.XeC8AVIkcKivUVMVwbGXr>p {\n    margin: 10px 0;\n}", "",{"version":3,"sources":["webpack://src/components/layouts/Sponsor.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;IACX,0CAA0C;IAC1C,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".sponsor {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    background-color: rgba(100, 100, 100, 0.2);\n    padding: 12px;\n    border-radius: 6px;\n}\n\n.logo {\n    height: 70px;\n    max-width: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.logo>img {\n    max-width: 200px;\n    max-height: 70px;\n}\n\n.sponsorInfo {\n    display: flex;\n    flex-direction: column;\n}\n\n.title {\n    margin: 16px 0 0;\n}\n\n.sponsorInfo>p {\n    margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsor": "_2qskn_HwRIf9OTSlEVvFvp",
	"logo": "_3kNWARuAkoD226IOvSXMF-",
	"sponsorInfo": "XeC8AVIkcKivUVMVwbGXr",
	"title": "_27Vf5znypLKj8wxMEP1F4G"
};
export default ___CSS_LOADER_EXPORT___;
