// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3hmqk7JPUjtkkOO4WmM_F- {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._3JBFxJ_NTblwi15Sp6Maf0 {\n    float: right;\n    margin: 0 0 10px 20px;\n}\n\n._3JBFxJ_NTblwi15Sp6Maf0 img {\n    display: block;\n    max-height: 200px;\n    margin-bottom: 20px;\n}\n\n._3JBFxJ_NTblwi15Sp6Maf0 span {\n    display: block;\n    text-align: center;\n}\n\n._1_CIWCQIxj0wGl0dHaq6sG {\n    clear: both;\n    max-width: 600px;\n    margin: 0 auto;\n}\n\n._14yyezQOrXmE_5mUtpOEgn {\n    width: 80%;\n    font-size: 20px;\n    font-weight: bold;\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/pages/Books.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".books {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.book {\n    float: right;\n    margin: 0 0 10px 20px;\n}\n\n.book img {\n    display: block;\n    max-height: 200px;\n    margin-bottom: 20px;\n}\n\n.book span {\n    display: block;\n    text-align: center;\n}\n\n.bookExcerpt {\n    clear: both;\n    max-width: 600px;\n    margin: 0 auto;\n}\n\n.quote {\n    width: 80%;\n    font-size: 20px;\n    font-weight: bold;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"books": "_3hmqk7JPUjtkkOO4WmM_F-",
	"book": "_3JBFxJ_NTblwi15Sp6Maf0",
	"bookExcerpt": "_1_CIWCQIxj0wGl0dHaq6sG",
	"quote": "_14yyezQOrXmE_5mUtpOEgn"
};
export default ___CSS_LOADER_EXPORT___;
