// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3CB2pJlJtiFO-ILkhjhtjF {\n    display: flex;\n    flex-direction: column;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n._23mm96IgM0kQgqQJbFTRgu {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-gap: 20px;\n}\n\n._2KcqMgkHMH-SwcpKR-bi_t {\n    font-size: 16px;\n    color: #f00;\n}\n\n@media screen and (min-width: 688px) {\n    ._23mm96IgM0kQgqQJbFTRgu {\n        display: grid;\n        grid-template-columns: repeat(2, minmax(0, 1fr));\n        grid-gap: 20px;\n    }\n}\n\n@media screen and (min-width: 1400px) {\n    ._3CB2pJlJtiFO-ILkhjhtjF {\n        max-width: 1400px;\n    }\n    ._23mm96IgM0kQgqQJbFTRgu {\n        display: grid;\n        grid-template-columns: repeat(3, minmax(0, 1fr));\n        grid-gap: 20px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/pages/Sponsors.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI;QACI,aAAa;QACb,gDAAgD;QAChD,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,aAAa;QACb,gDAAgD;QAChD,cAAc;IAClB;AACJ","sourcesContent":[".sponsors {\n    display: flex;\n    flex-direction: column;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.sponsorsGrid {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-gap: 20px;\n}\n\n.discountCode {\n    font-size: 16px;\n    color: #f00;\n}\n\n@media screen and (min-width: 688px) {\n    .sponsorsGrid {\n        display: grid;\n        grid-template-columns: repeat(2, minmax(0, 1fr));\n        grid-gap: 20px;\n    }\n}\n\n@media screen and (min-width: 1400px) {\n    .sponsors {\n        max-width: 1400px;\n    }\n    .sponsorsGrid {\n        display: grid;\n        grid-template-columns: repeat(3, minmax(0, 1fr));\n        grid-gap: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sponsors": "_3CB2pJlJtiFO-ILkhjhtjF",
	"sponsorsGrid": "_23mm96IgM0kQgqQJbFTRgu",
	"discountCode": "_2KcqMgkHMH-SwcpKR-bi_t"
};
export default ___CSS_LOADER_EXPORT___;
