import React from 'react'
import { Link } from 'react-router-dom'

import styles from './About.module.css'
import princey from '../../assets/img/daniel-prince.png'

export const About = () => (
    <div className={styles.about}>
        <h1>About</h1>

        <div className={styles.thanks}>
            <div className={styles.princey}>
                <img src={princey} alt="Daniel Prince" />
            </div>
            <div>
                <p>Hi, I'm <a href="https://twitter.com/PrinceySOV" target="_blank">Daniel Prince</a>, the host of Once BITten.</p>
                <p>Thank you for visiting the site and for listening to my Bitcoin podcast!</p>

                <p>One day you might become as convinced as me and many others out there that this technology is a force for good in the world and a way to change your life for the better forever. </p>

                <p>I hope this podcast series will help educate you and your loved ones about Bitcoin.</p>
            </div>
        </div>

        <div className={styles.whois}>
            <h2>Wait - Who is Daniel Prince?</h2>

            <p>I am just another pleb trying to find answers in this crazy world. I spent 18 years working in a Finance career in Foreign Exchange Markets. Yes, I dealt in 100's of millions of dollars in fiat currencies every day and it's shameful to say that I had no idea what money was or even questioned it. I just put my head down to work as hard as I could to try and earn a living to support my family. </p>

            <p>I was another lemming caught in the trap of the rat race and was fully committed to the blind quest for 'cash'. </p>

            <p>Manning up to the fact you know almost nothing about how our financial system works and that you have been a slave to their system for many of your most productive and capable years is one of the bitterest pills you will ever swallow. </p>

            <p>You will find this is a common paradox amongst us Bitcoiners.</p>

            <p>Bitcoin is the grand humbler. It makes you question everything. It is only the curious or the desperate that truly see what it is and what it can become.</p>

            <p>So, here it might begin for you, this uniquely individual trip into the rabbit hole that we call Bitcoin.</p>

            <p>Welcome friend, I hope my humble effort to provide some value back to the space that has taught me so much will help guide you through some of this personal enhancing experience!</p>

            <p>Let's go!</p>

            <p>Dan.</p>

            <p>P.s - You can learn more about my story of quitting the rat race to travel the world with my family via the sharing economy by heading over to <a href="http://www.princesoffthegrid.weebly.com" target="_blank">our website</a> where you can also find information about the book I wrote called <Link to="/books">Choose Life</Link>.</p>
        </div>
    </div>
)