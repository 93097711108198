import React, { useState } from 'react'

import styles from './Links.module.css'
import apple from '../assets/img/links/apple_podcasts.png'
import breaker from '../assets/img/links/breaker.png'
import castbox from '../assets/img/links/castbox.png'
import google from '../assets/img/links/google_podcasts.png'
import overcast from '../assets/img/links/overcast.png'
import pocket from '../assets/img/links/pocket_casts.png'
import radiopublic from '../assets/img/links/radiopublic.png'
import spotify from '../assets/img/links/spotify.png'
import stitcher from '../assets/img/links/stitcher_dark.png'
import youtube from '../assets/img/links/youtube.png'

const castLinks = [
    ['Apple podcasts', apple, 'https://podcasts.apple.com/gb/podcast/once-bitten/id1497540130'],
    ['Breaker', breaker, 'https://www.breaker.audio/once-bitten'],
    ['Castbox', castbox, 'https://castbox.fm/channel/Once-BITten!-id2613071?country=gb'],
    ['Google podcasts', google, 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xMzQ2ZjllOC9wb2RjYXN0L3Jzcw=='],
    ['Overcast', overcast, 'https://overcast.fm/itunes1497540130/once-bitten-an-educational-bitcoin-podcast'],
    ['Pocket Casts', pocket, 'https://pca.st/91aj56c7'],
    ['Radio Public', radiopublic, 'https://radiopublic.com/once-bitten-69qn03'],
    ['Spotify', spotify, 'https://open.spotify.com/show/4wWzXFEyAJtM6aOLA6c4Q2'],
    ['Sticher', stitcher, 'https://www.stitcher.com/podcast/once-bitten'],
    ['YouTube', youtube, 'https://www.youtube.com/playlist?list=PLWIEN8RK7PKGlNjo_Am19vTNxUuSj41Kv'],
]

const LinkItem = ({ data }) => (<a href={data[2]} target="_blank" title={data[0]}><img src={data[1]} alt={data[0]} /></a>)

export const Links = () => {
    const listen = []
    for (const item of castLinks) {
        listen.push(<LinkItem key={item[0]} data={item} />)
    }

    return (
        <div className={styles.links}>
            {listen}
        </div>
    )
}