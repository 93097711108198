import React, { Fragment, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import styles from './Episodes.module.css'
import { useEpisodes } from '../../hooks'
import { EpisodeSmall } from '../EpisodeSmall'

export const Episodes = ({ setSelectedEp }) => {
    const { guid } = useParams()
    const history = useHistory()
    const [filterText, setFilterText] = useState('')
    const episodes = useEpisodes()

    if (!episodes) return 'Loading...'


    const onKeyUp = e => {
        switch (e.key) {
            case 'Escape':
                setFilterText('')
                break;
            default:
                return
        }
    }

    const notInDate = date => date.toLowerCase().indexOf(filterText) < 0
    const notInTitle = title => title.toLowerCase().indexOf(filterText) < 0

    const eps = []
    let idx = 0
    for (const ep of episodes) {
        if (notInDate(ep.pubDate) && notInTitle(ep.title)) continue
        if (ep.guid === guid) setSelectedEp(ep)
        eps.push(
            <Fragment key={ep.title}>
                <hr />
                <a className={styles.epTitle} href="#" onKeyUp={onKeyUp} onClick={e => {
                    e.preventDefault()
                    setFilterText('')
                    history.push(`/episodes/${ep.guid}`)
                }}><EpisodeSmall data={ep} /></a>
            </Fragment>
        )
        idx++
    }

    return (
        <div>
            <div className={styles.pageTop}>
                <h1>Episodes</h1>
                <p>Click on a title to load an episode</p>
                <div className={styles.filter}>
                    <span>Search:</span>
                    <input value={filterText} onChange={e => setFilterText(e.target.value.toLowerCase())} onKeyUp={onKeyUp} />
                    <span className={styles.clearFilter} onClick={() => setFilterText('')}>&#8855;</span>
                </div>
            </div>
            {eps}
        </div>
    )
}